import * as React from 'react';
import {
    Dialog,
    DialogType,
    Stack,
    Link,
    PrimaryButton,
    DefaultButton,
    TextField
} from "@fluentui/react";
import { IAppState } from '../../common/redux/AppState';
import { connect } from 'react-redux';
import { UserContext } from '../../common/models/Context';
import TimedMessageBar from '../TimedMessageBar/Index';
import { CommonService } from '../../common/services/CommonService';

interface IAskHRProps {
    hidden: boolean;
    onDismiss: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => any;
    userContext: UserContext;
    commonService: CommonService;
}

interface IAskHRState {
    showPrivacyNotice: boolean;
    subject: string;
    description: string;
    userMessage?: string;
    userMessageSuccess: boolean;
}

export class AskHR extends React.Component<IAskHRProps, IAskHRState> {
    constructor(props: IAskHRProps) {
        super(props);

        this.state = {
            showPrivacyNotice: false,
            subject: '',
            description: '',
            userMessage: '',
            userMessageSuccess: false
        };
    }

    public render(): JSX.Element {
        const token = {
            tenGap: {
                childrenGap: 10
            }
        };
        return (
            <Dialog
                hidden={this.props.hidden}
                onDismiss={this.onDismiss}
                minWidth="100%"
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'AskHR',
                    styles: {
                        content: {
                            maxWidth: 600,
                            minWidth: 600,
                            margin: '0 auto',
                            whiteSpace: 'pre-line'
                        }
                    }
                }}
                modalProps={{
                    isBlocking: false,
                    isDarkOverlay: true
                }}
            >
                <div hidden={this.state.showPrivacyNotice}>
                    <Stack tokens={token.tenGap} styles={{ root: { minHeight: 267 } }}>
                        <TextField
                            label="Subject"
                            placeholder="Enter subject"
                            value={this.state.subject}
                            required={true}
                            onChange={this.onSubjectChange}
                        />
                        <TextField
                            label="Description"
                            multiline={true}
                            rows={4}
                            placeholder="Enter description"
                            value={this.state.description}
                            onChange={this.onDescriptionChange}
                        />
                        {this.state.userMessage && (
                            <TimedMessageBar
                                isSuccess={this.state.userMessageSuccess}
                                onDismiss={this.onDismissMessageBar}
                            >
                                {this.state.userMessage}
                            </TimedMessageBar>
                        )}
                        <div>
                            By clicking <b>Ask</b>, you acknowledge and agree to the terms and conditions set forth in
                            the <Link underline onClick={this.onPrivacyNoticeClick}>Microsoft Privacy Notice</Link>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <PrimaryButton
                                onClick={this.onAskClick}
                                text="Ask"
                                styles={{ root: { marginRight: 10 } }}
                            />
                            <DefaultButton onClick={this.onDismiss} text="Cancel" />
                        </div>
                    </Stack>
                </div>
                <div hidden={!this.state.showPrivacyNotice} style={{ height: 267, overflow: 'auto' }}>
                    <h3>AskHR&apos;s Privacy Notice</h3>
                    <p>
                        Any information collected by this form and website will be associated with your name, personnel
                        number, and contact information. It will be stored in Dynamics CRM in Redmond, Washington, USA
                        and will be viewable by Microsoft HR personnel for the purpose of responding to your inquiry and
                        by Microsoft IT CRM support staff when necessary for system maintenance. The Microsoft HR
                        personnel and Microsoft IT CRM support staff, which may include vendors hired by Microsoft, are
                        located worldwide where Microsoft operates.
                    </p>
                    <p>
                        Microsoft may also access and/or disclose information if we believe such action is necessary to:
                        (a) comply with the law or legal process served on Microsoft; (b) protect and defend the rights
                        or property of Microsoft; or (c) act in urgent circumstances to protect the personal safety of
                        Microsoft employees or contingent staff, users of Microsoft services or members of the public.
                        By using this form, you consent to this use of the data.
                    </p>
                    <h3>Notification de AskHR relative à la protection des données personnelles</h3>
                    <p>
                        Toutes informations recueillies par le biais de ce formulaire et site Internet comprendront
                        votre nom, votre numéro d&apos;employé et vos coordonnées. Elles seront enregistrées dans
                        Dynamics CRM à Redmond, État de Washington, USA, et pourront être consultées par le personnel
                        des Ressources Humaines de Microsoft dans le but de répondre à vos demandes de renseignements
                        ainsi que par l&apos;équipe de support IT CRM de Microsoft, si besoin pour la maintenance du
                        système. Le personnel des Ressources Humaines de Microsoft et l&apos;équipe de support IT CRM de
                        Microsoft, pouvant comprendre certains prestataires auxquels Microsoft fait appel, sont répartis
                        dans tous les pays où Microsoft est implanté.
                    </p>
                    <p>
                        Microsoft peut également accéder à des informations et/ou divulguer des informations si cela lui
                        apparaît nécessaire pour : (a) se conformer à la loi ou à une procédure judiciaire engagée
                        contre elle ; (b) protéger et défendre ses droits et ses biens ; ou (c) agir, dans des
                        situations d&apos;urgence, pour protéger la sécurité personnelle de ses salariés et autres
                        employés externes ou intérimaires, des utilisateurs de ses services ou du public. En utilisant
                        ce formulaire, vous consentez à l&apos;utilisation de ces données par Microsoft.
                    </p>
                    <div style={{ textAlign: 'right' }}>
                        <PrimaryButton onClick={this.onBackClick} text="Back" styles={{ root: { marginRight: 10 } }} />
                    </div>
                </div>

                <div style={{ fontSize: 13, lineHeight: 'normal', color: '#505050', marginTop: 15 }}>
                    <b>AskHR Disclaimer:</b> Any information that you enter into this form will be associated with your
                    name, personnel number, and contact information. It will be stored in Dynamics CRM in Redmond,
                    Washington, USA and will viewable by HR personnel, which may include vendors hired by Microsoft, for
                    the purpose of responding to your inquiry. By using this form, you consent to this use of the data.
                    For a more detailed explanation of how this data will be used and stored, please see the{' '}
                    <Link underline onClick={this.onPrivacyNoticeClick}>privacy notice</Link>
                </div>
            </Dialog>
        );
    }

    private onSubjectChange = (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ): void => {
        this.setState({
            subject: newValue || ''
        });
    };

    private onDescriptionChange = (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ): void => {
        this.setState({
            description: newValue || ''
        });
    };

    private onPrivacyNoticeClick = (): void => {
        this.setState({
            showPrivacyNotice: true
        });
    };

    private onBackClick = (): void => {
        this.setState({
            showPrivacyNotice: false
        });
    };

    private onDismissMessageBar = (): void => {
        this.setState({
            userMessage: ''
        });
    };

    private onDismiss = (): void => {
        this.setState({
            showPrivacyNotice: false,
            userMessage: '',
            subject: '',
            description: ''
        });
        this.props.onDismiss();
    };

    private onAskClick = async (): Promise<void> => {
        if (!this.state.subject) {
            this.setState({
                userMessageSuccess: false,
                userMessage: 'Subject is required to create an AskHR case.'
            });
        } else {
            const result = await this.props.commonService.createAskHrCase({
                ...this.props.userContext.context,
                Description: this.state.description,
                Subject: this.state.subject
            });
            if (result.IsError) {
                this.setState({
                    userMessageSuccess: false,
                    userMessage: 'There is an error in sending an inquiry. Please try again later.'
                });
            } else {
                this.setState({
                    userMessageSuccess: true,
                    userMessage:
                        'Inquiry successfully sent. You will receive an email response shortly. You can now close this window.'
                });
            }
        }
    };
}

const mapStateToProps = (state: IAppState) => ({
    userContext: state.root.userContext
});

const AskHRContainer = connect(mapStateToProps)(AskHR);

export default AskHRContainer;
