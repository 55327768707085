import * as React from 'react';
import { render } from 'react-dom';
import { ShellWithStore } from './ShellWithStore';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { EmployeeDataService } from './common/services/EmployeeDataService';
import { Stack, Announced, Overlay, ProgressIndicator, StackItem, initializeIcons } from "@fluentui/react";
import { IAppState } from './common/redux/AppState';
import { Header } from './components/Header/index';
import { ReduxContext } from '@employee-experience/common/lib/ReduxContext';
import './App.scss';
import { connect } from 'react-redux';
import { UserContext } from './common/models/Context';
import { AppType } from './common/models/AppType';
import { CommonService } from './common/services/CommonService';
import { login, switchApp } from './common/redux/actions';
import { Paths } from './paths';
import ErrorBoundary from './components/ErrorBoundary';
import '../public/favicon.ico';

interface IAppProps {
    userContext: UserContext;
    currentApp: AppType;
    isLoading: number;
}

function App(props: IAppProps): React.ReactElement {
    const [user] = useLoginOnStartup();
    const { httpClient } = React.useContext(ComponentContext);
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const [defaultPage, setDefaultPage] = React.useState('');

    initializeIcons();
    const commonService = new CommonService(httpClient);
    const employeeDataService = new EmployeeDataService(httpClient);

    const services = {
        commonService,
        employeeDataService
    };

    const programInstance = useSelector((state: IAppState) => {
        return state.root.programInstance;
    });

    const isLoading = useSelector((state: IAppState) => {
        return state.root.isLoading > 0;
    });

    const currentApp = useSelector((state: IAppState) => {
        return state.root.currentApp;
    });

    const userContext = useSelector((state: IAppState) => {
        return state.root.userContext;
    });

    React.useEffect(() => {
        if (user) {
            const email = user.email.split('@')[0];

            employeeDataService.getUserPersonnelNumber(email).then(resp => {
                employeeDataService.getShortProfileByPernrs([resp.personnelNumber]).then(profile => {
                    const userContext: UserContext = {
                        context: {
                            programInstanceID: parseInt(programInstance?.key.toString() || '0'),
                            wobAlias: email,
                            wobPerner: resp.personnelNumber,
                            loggedInAlias: email,
                            loggedInPerner: resp.personnelNumber
                        },
                        ssaContext: {
                            loggedInAlias: email,
                            loggedInPerner: resp.personnelNumber,
                            programInstanceID: parseInt(REACT_APP_SSA_EMPLOYEE_INSTANCE_ID, 10),
                            poolOwnerAlias: '',
                            poolOwnerPerner: 0
                        },
                        profile: {
                            loggedInProfile: profile[0],
                            wobProfile: profile[0]
                        }
                    };
                    let count = 0;
                    const handlePermissions = (): void => {
                        count++;
                        if (count === 2) {
                            dispatch(login(userContext));

                            const canAccessTSSA =
                                userContext.authZPermissions &&
                                userContext.authZPermissions.userActions &&
                                userContext.authZPermissions.userActions.length;
                            const canAccessSpecialAwards =
                                userContext.ssaAuthZPermissions &&
                                userContext.ssaAuthZPermissions.userActions &&
                                userContext.ssaAuthZPermissions.userActions.length;
                            const inTSSA =
                                window.location.pathname.toLowerCase().indexOf(Paths.SA.toLowerCase()) !== -1;
                            const inSpecialAwards =
                                window.location.pathname.toLowerCase().indexOf(Paths.SSA.toLowerCase()) !== -1;

                            if (currentApp === AppType.None) {
                                if (canAccessTSSA && (inTSSA || (!canAccessSpecialAwards && !inSpecialAwards))) {
                                    setDefaultPage(Paths.SA);
                                    dispatch(switchApp(AppType.TargetedSSA));
                                } else if (canAccessSpecialAwards && !inTSSA) {
                                    setDefaultPage(Paths.SSA);
                                    dispatch(switchApp(AppType.SpecialAwards));
                                } else {
                                    setDefaultPage(Paths.UnAuthorized);
                                    dispatch(switchApp(AppType.Unauthorized));
                                }
                            }
                        }
                    };

                    commonService
                        .getWobUserPermissions(userContext.context)
                        .then(actions => {
                            userContext.authZPermissions = actions;
                        })
                        .finally(handlePermissions);
                    commonService
                        .getSSAUserPermissions(userContext.ssaContext)
                        .then(actions => {
                            userContext.ssaAuthZPermissions = actions;
                        })
                        .finally(handlePermissions);
                });
            });
        }
    }, [user]);

    const onSkipMainClick = () => {
        var item = document.querySelector<HTMLInputElement>("[role='main'] [tabindex='0']");
        item && item.focus();
    }

    if (user && userContext.context) {
        return (
            <BrowserRouter>
                <Stack verticalFill={true} className="ms-depth-4">
                     <Announced
                        message={isLoading ? 'loading page' : 'page loaded'}
                        role="alert"
                        aria-live="assertive"
                    /> 
                    <Overlay styles={{ root: { zIndex: 2000000 } }} hidden={!isLoading}>
                        <ProgressIndicator styles={{ itemProgress: { paddingTop: 0 } }} />
                    </Overlay>
                    <StackItem>
                        <a className="skip-main" href="javascript:void(0);" onClick={onSkipMainClick}>
                            Skip to main content
                        </a>
                    </StackItem>
                    <StackItem grow={0} shrink={0}>
                        <Header {...services} />
                    </StackItem>
                    <ErrorBoundary>
                        <Routes defaultPage={defaultPage} />
                    </ErrorBoundary>
                </Stack>
            </BrowserRouter>
        );
    }
    return <></>;
}

const mapStateToProps = (state: IAppState): IAppProps => ({
    userContext: state.root.userContext,
    isLoading: state.root.isLoading,
    currentApp: state.root.currentApp
});

const AppContainer = connect(mapStateToProps)(App);

export default AppContainer;

render(
    <ShellWithStore>
        <AppContainer />
    </ShellWithStore>,
    document.getElementById('app')
);
