import { ReduxContext } from "@employee-experience/common/lib/ReduxContext"
import { ActionButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { setDialog } from '../../common/redux/actions';
import { IAppState } from '../../common/redux/AppState';

export function PeriodSelector(): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const programInstance = useSelector((state: IAppState) => {
        return state.root.programInstance;
    });

    const onClick = (): void => {
        dispatch(setDialog(2));
    };
    
    const periodText = programInstance ? programInstance.programInstanceName : '';

    return (
        <>
            <ActionButton style={{ color: 'white' }} text={periodText} onClick={onClick} />
        </>
    );
}
